import React, { useState } from 'react'

const URL = 'https://motorbgc.inovamind.com.br/'

export default function Redirect() {
  const [seconds, setSeconds] = useState(60)

  if (seconds <= 0) window.location.replace(URL)

  React.useEffect(() => {
    setInterval(() => {
      setSeconds((x) => x - 1)
    }, 1000)
  }, [])

  return (
    <div>
      <p style={{ fontSize: 25, marginBottom: 15 }}>
        <strong>
          O ambiente de staging foi desativado e você será redirecionado para o painel do Motor BGC de produção
        </strong>
      </p>
      <p style={{ fontSize: 20, marginBottom: 20 }}><strong>Obs</strong>: para acessar o novo painel você deve usar a senha temporária enviada para seu email. Caso não tenha recebido, entre em contato com o suporte.</p>
      <p style={{ fontSize: 25, marginBottom: 15 }}>
        O redirecionamento irá ocorrer em {seconds} segundos ou <a href={URL}>clique aqui</a> para redirecionar agora
      </p>
    </div>
  )
}
